import React from 'react';
import styled from 'styled-components';
import { Table, Button, Icon } from 'rbx';

const Element = styled.div``;

const ActionButtons = ({ onDelete, onEdit, config }) => {
  const [deleting, setDeleting] = React.useState(false);

  return (
    <Element>
      <Button.Group hasAddons>
        <Button onClick={() => onEdit(config)}>
          <Icon size="small">
            <i className="fas fa-edit" />
          </Icon>
        </Button>
        {deleting ? (
          <span>loading...</span>
        ) : (
          <Button
            onClick={async () => {
              setDeleting(true);
              await onDelete(config);
              setDeleting(false);
            }}
            disabled={deleting}
          >
            <Icon size="small">
              <i className="fas fa-trash has-text-grey" />
            </Icon>
          </Button>
        )}
      </Button.Group>
    </Element>
  );
};

const List = ({ posConfigs, onDelete, onEdit }) => (
  <>
    {posConfigs.map(item => (
      <Table.Row>
        <Table.Cell>{item.platform}</Table.Cell>
        <Table.Cell>{item.unique_name}</Table.Cell>
        <Table.Cell>{item.unique_id}</Table.Cell>
        <Table.Cell>
          {item?.service_type &&
          Array.isArray(item?.service_type) &&
          item?.service_type.find(ele => ele === 'PICKUP') ? (
            <i id="CheckBox" className="fas fa-check-square" />
          ) : (
            <i id="CheckBox" className="far fa-square" />
          )}
        </Table.Cell>
        <Table.Cell>
          {item?.service_type &&
          Array.isArray(item?.service_type) &&
          item?.service_type.find(ele => ele === 'DELIVERY') ? (
            <i id="CheckBox" className="fas fa-check-square" />
          ) : (
            <i id="CheckBox" className="far fa-square" />
          )}
        </Table.Cell>
        <Table.Cell>
          {item?.service_type &&
          Array.isArray(item?.service_type) &&
          item?.service_type.find(ele => ele === 'DINEIN') ? (
            <i id="CheckBox" className="fas fa-check-square" />
          ) : (
            <i id="CheckBox" className="far fa-square" />
          )}
        </Table.Cell>
        <Table.Cell>
          {item?.service_type &&
          Array.isArray(item?.service_type) &&
          item?.service_type.find(ele => ele === 'TABLE_BOOKING') ? (
            <i id="CheckBox" className="fas fa-check-square" />
          ) : (
            <i id="CheckBox" className="far fa-square" />
          )}
        </Table.Cell>
        <Table.Cell>
          <ActionButtons
            config={item}
            onEdit={onEdit}
            onDelete={onDelete}
            key={item.unique_id}
          />
        </Table.Cell>
      </Table.Row>
    ))}
  </>
);

export default List;
