import React from 'react';
import gql from 'graphql-tag';
import { useStoreState } from 'easy-peasy';
import { forEach } from 'lodash';

import client from '../../../../utils/apolloClient';
import { getUserRoles } from '../../../../utils/fetchStatic';

const userAccessQuery = gql`
  query userAccessQuery($userId: String!) {
    fetch_user(input: { user_id: $userId }) {
      user_id
      object_id
      access {
        place_id
      }
    }
  }
`;

export const fetchUserAccess = async ({ userId }) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: userAccessQuery,
      variables: {
        userId,
      },
      fetchPolicy: 'network-only',
    });

    if (data && data?.data?.fetch_user) {
      return data.data.fetch_user;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const getAuthUrlQuery = gql`
  query getAuthUrlQuery($placeId: String) {
    get_auth_url(input: { place_id: $placeId }) {
      auth_url
    }
  }
`;

export const getAuthUrl = async ({ placeId }) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: getAuthUrlQuery,
      variables: {
        placeId,
      },
      fetchPolicy: 'network-only',
    });

    if (data && data?.data?.get_auth_url?.auth_url) {
      return data?.data?.get_auth_url?.auth_url;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const useUserAccessData = ({ userId }) => {
  const [userAccess, setUserAccess] = React.useState(null);

  React.useEffect(() => {
    const fetchFunc = async () => {
      const res = await fetchUserAccess({ userId });
      if (res) {
        setUserAccess(res);
      } else {
        // pass
      }
    };

    fetchFunc();
  }, [userId]);

  return userAccess;
};

const usersQuery = gql`
  query usersQuery($input: SearchInput) {
    search_users(input: $input) {
      total_size
      total_pages
      user_listing {
        last_login
        user_id
        first_name
        last_name
        display_name
        email
        date_of_birth
        gender
        status
        role
        contact {
          type
          value
          display
          display_order
          is_primary
        }
        audit {
          created_at
          created_by
          updated_at
          updated_by
        }
        error {
          code
          description
        }
      }
    }
  }
`;

export const searchUser = async ({ input }) => {
  try {
    const data = await client.clientPrivate.query({
      client: client.clientPrivate,
      query: usersQuery,
      variables: {
        input,
      },
      fetchPolicy: 'network-only',
    });

    if (
      data &&
      data?.data?.search_users?.user_listing &&
      Array.isArray(data.data.search_users.user_listing) &&
      data.data.search_users.user_listing[0]
    ) {
      return { error: null, data: data.data.search_users.user_listing[0] };
    }
    return { error: null, data: null };
  } catch (error) {
    console.log(error);
    return { error, data: null };
  }
};

const parseData = parseValue => {
  const final = [];
  forEach(parseValue, item => {
    final.push({ value: item.name, label: item.description });
  });
  return final;
};

export const useUserData = ({ email }) => {
  const { userId: adminUserId } = useStoreState(state => state.auth);
  const [userIsLoading, setUserIsLoading] = React.useState(false);

  const inputFunc = role => {
    const input = {};
    const user_filter = { status: 'ACTIVE', role };
    Object.assign(user_filter, email && { email });
    Object.assign(
      input,
      { user_id: adminUserId },
      { from: 0, size: 20 },
      { sort: 'FIRSTNAME_DESC' },
      {
        filter: {
          user_filter,
        },
      },
    );
    return input;
  };

  const inputRoleFalse = inputFunc(false);

  const inputRoleTrue = inputFunc(true);

  const fetchFunc = async () => {
    setUserIsLoading(true);
    let res = null;

    const res1 = await searchUser({ input: inputRoleFalse });
    if (res1.data) {
      res = res1.data;
    }

    const res2 = await searchUser({ input: inputRoleTrue });
    if (res2.data) {
      res = res2.data;
    }

    const rolesFromCMS = await getUserRoles();

    if (res) {
      res.rolesFromCMS = parseData(rolesFromCMS);
    }

    setUserIsLoading(false);

    return res;
  };

  return [userIsLoading, fetchFunc];
};
