import React from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import Details from './Details';
import client from '../../../../utils/apolloClient';
import { Loading, Message } from '../../../../components/elements';

const posConfigurationQuery = gql`
  query posConfigurationQuery($placeId: String!) {
    fetch_place(input: { place_id: $placeId }) {
      pos_configuration {
        platform
        unique_name
        unique_id
        service_type
      }
    }
  }
`;

const PosIntegrations = props => {
  const { data, loading, error, refetch } = useQuery(posConfigurationQuery, {
    variables: {
      placeId: props.values.place_id,
    },
    client: client.clientPrivate,
  });

  console.log(data);

  const posConfigs = data?.fetch_place?.pos_configuration?.unique_id
    ? [data?.fetch_place?.pos_configuration]
    : [];

  return (
    <>
      {error && <Message>{error.message}</Message>}
      {loading && <Loading />}
      {!loading && !error && (
        <Details
          posConfigs={posConfigs}
          refetch={refetch}
          placeId={props.place.place_id}
        />
      )}
    </>
  );
};

export default PosIntegrations;
