import gql from 'graphql-tag';
import client from '../../../../../utils/apolloClient';

// mutation MyMutation {
//     create_menu_section(input: {user_id: "", status: "", place_id: "", name: "", menu_section_id: "", menu_id: ""})
//   }

//   input =

// fbd59e00-9d73-4438-89ab-c31bb27707ec

const updateMenuSectionsMutation = gql`
  mutation updateMenuSectionsMutation($input: MenuSectionInput) {
    update_menu_section(input: [$input]) {
      place_id
      menu_id
      menu_section_id
      error {
        description
      }
    }
  }
`;

const createMenuSectionsMutation = gql`
  mutation createMenuSectionsMutation($input: MenuSectionInput) {
    create_menu_section(input: $input) {
      place_id
      menu_id
      menu_section_id
      error {
        description
      }
    }
  }
`;
export const createMenuSections = ({
  user_id,
  status,
  place_id,
  name,
  menu_section_id,
  menu_id,
  section_hour,
}) => {
  const input = {
    user_id,
    status,
    place_id,
    name,
    menu_section_id,
    menu_id,
    section_hour,
  };
  return new Promise((resolve, reject) => {
    client.clientPrivate
      .mutate({
        client: client.clientPrivate,
        mutation: createMenuSectionsMutation,
        variables: {
          input,
        },
      })
      .then(({ data }) => {
        if (!data.create_menu_section.error) {
          resolve(true);
        } else {
          resolve(data.create_menu_section.error[0].description);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

export const updateMenuSections = ({
  user_id,
  status,
  place_id,
  name,
  menu_section_id,
  menu_id,
  section_hour,
}) => {
  const input = {
    user_id,
    status,
    place_id,
    name,
    menu_section_id,
    menu_id,
    section_hour,
  };
  return new Promise((resolve, reject) => {
    client.clientPrivate
      .mutate({
        client: client.clientPrivate,
        mutation: updateMenuSectionsMutation,
        variables: {
          input,
        },
      })
      .then(({ data }) => {
        if (!data.update_menu_section[0].error) {
          resolve(true);
        } else {
          resolve(data.update_menu_section[0].error[0].description);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

export const updateMenuSections2 = async ({
  user_id,
  is_time_based_category_discount,
  is_club_deal,
  discount,
  menu_section_id,
}) => {
  const input = {
    user_id,
    is_time_based_category_discount,
    is_club_deal,
    discount,
    menu_section_id,
  };

  try {
    const res = await client.clientPrivate.mutate({
      client: client.clientPrivate,
      mutation: updateMenuSectionsMutation,
      variables: {
        input,
      },
    });

    if (
      res &&
      Array.isArray(res?.data?.update_menu_section?.error) &&
      res.data.update_menu_section.error[0]
    ) {
      return {
        error: res.data.update_menu_section.error[0]?.description,
        isError: true,
      };
    }
    return {
      error: null,
      isError: false,
    };
  } catch (error) {
    return {
      error:
        "Oops! Something didn't work as expected. Please contact support if the issue persists.",
      isError: true,
    };
  }
};

const searchSectionsSettingsQuery = gql`
  query searchSections($input: SearchInput) {
    search_menu_sections(input: $input) {
      menu_section_listing {
        menu_section_id
        place_id
        menu_id
        name
        hours
        section_hour {
          day
          is_active
          option {
            type
            start
            end
            start_in_sec
            end_in_sec
          }
        }
        is_time_based_category_discount
        is_club_deal
        discount {
          discount_type
          value
        }
      }
    }
  }
`;

export const fetchAllCategoriesOfThePlace = ({
  user_id,
  place_id,
  menu_id,
}) => {
  const input = {
    user_id,
    size: 50,
    from: 0,
    sort: 'UPDATED_DESC',
  };
  const menu_section_filter = { status: 'ACTIVE', menu_id };
  Object.assign(input, {
    filter: { place_filter: { place_id }, menu_section_filter },
  });
  return new Promise((resolve, reject) => {
    client.clientPrivate
      .query({
        client: client.clientPrivate,
        query: searchSectionsSettingsQuery,
        variables: {
          input,
        },
        fetchPolicy: 'network-only',
      })
      .then(({ data }) => {
        if (Array.isArray(data?.search_menu_sections?.menu_section_listing)) {
          resolve(data.search_menu_sections.menu_section_listing);
        } else {
          resolve([]);
        }
      })
      .catch(error => {
        console.log(error);
        reject(error);
      });
  });
};

export default {};
