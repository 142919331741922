/* eslint-disable func-names */
import React from 'react';

import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Label } from 'rbx';
// import { toast } from 'react-toastify';

import {
  CheckBox,
  Select,
  InputAddOn,
} from '../../../../../components/elements';
import Toggle from './Toggle';

yup.addMethod(yup.string, 'testNonNegativeValue', function(errorMessage) {
  return this.test(`test-non-negative-value`, errorMessage, function(value) {
    const { path, createError } = this;
    const num = +value;

    return num >= 0 || createError({ path, message: errorMessage });
  });
});

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 80vh;
      padding: 0rem 1rem;
    }
    .modal-card {
      max-height: calc(100vh - 140px);
      position: absolute;
      width: 40rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1.5rem 1rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: flex-end;
      border: 0px;
      padding: 1rem;
      background: white;
    }
    a {
      font-size: 1rem;
    }
  }
`;

const GridContainer2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const DISCOUNTTYPES = [
  { value: 'FLAT', label: 'FLAT' },
  { value: 'PERCENTAGE', label: 'PERCENTAGE' },
];

const FormContent = props => {
  const {
    onClose,
    setFieldValue,
    values,
    handleSubmit,
    loading,
    errors,
    touched,
  } = props;

  return (
    <>
      <GridContainer2>
        <Label>Apply Discount</Label>
        <Toggle
          checked={values.is_time_based_category_discount}
          onToggle={() =>
            setFieldValue(
              'is_time_based_category_discount',
              !values.is_time_based_category_discount,
            )
          }
        />
      </GridContainer2>
      <br />
      <GridContainer2>
        <div style={{ margin: '0 12px 0 0' }}>
          <Select
            label="Discount Type"
            options={DISCOUNTTYPES}
            value={[
              {
                value: values.discount_type,
                label: values.discount_type,
              },
            ]}
            onChange={({ value }) => {
              setFieldValue('discount_value', '');
              setFieldValue('discount_type', value);
            }}
          />
        </div>

        {values.discount_type === 'FLAT' ? (
          <>
            <InputAddOn
              label="Discount Value"
              addonsText="$"
              isRight
              value={values.discount_value}
              onChange={e => {
                setFieldValue('discount_value', e.target.value);
              }}
              errors={errors.discount_value}
              touched={touched.discount_value}
              disableToastMessage
              type="number"
              min="1"
              max="100"
            />
          </>
        ) : (
          <>
            <InputAddOn
              label="Discount Value"
              addonsText="%"
              isLeft
              value={values.discount_value}
              onChange={e => {
                setFieldValue('discount_value', e.target.value);
              }}
              errors={errors.discount_value}
              touched={touched.discount_value}
              disableToastMessage
              type="number"
              min="1"
              max="100"
            />
          </>
        )}
      </GridContainer2>
      <br />
      <CheckBox
        label="Do not club with other deals"
        value={values.is_club_deal}
        onChange={() => {
          setFieldValue('is_club_deal', !values.is_club_deal);
        }}
      />
      <footer className="modal-card-foot">
        <button type="button" className="button" onClick={onClose}>
          cancel
        </button>
        <button
          type="button"
          className={`button is-primary ${loading && 'is-loading'}`}
          onClick={handleSubmit}
        >
          Save
        </button>
      </footer>
    </>
  );
};

const Form = withFormik({
  mapPropsToValues: ({ discountDetails }) => ({
    is_time_based_category_discount:
      discountDetails?.is_time_based_category_discount || false,
    is_club_deal: discountDetails?.is_club_deal || false,
    discount_type: discountDetails?.discount?.discount_type || 'FLAT',
    discount_value: discountDetails?.discount?.value || '',
    menu_section_id: discountDetails?.menu_section_id,
  }),

  validationSchema: yup.object().shape({
    discount_value: yup
      .number()
      .nullable()
      .transform((value, original) => (original === '' ? null : value))
      .min(1, 'Discount value must be at least 1')
      .max(100, 'Discount value must be less than or equal to 100')
      .positive('Discount value must be a positive number')
      .required('Discount value is required!'),
  }),

  handleSubmit: (values, { props }) => {
    const input = {
      is_time_based_category_discount: values.is_time_based_category_discount,
      is_club_deal: values.is_club_deal,
      discount: {
        discount_type: values.discount_type,
        value: values.discount_value,
      },
      menu_section_id: values.menu_section_id,
    };

    props.onSubmit({
      input,
    });
  },
  enableReinitialize: true,
  displayName: 'Add / Edit Discount Details',
})(FormContent);

const EditDiscountDetails = props => {
  const { isActive, onClose, loading, onSubmit, discountDetails } = props;

  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Add / Edit Discount Details</p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={onClose}
          />
        </header>
        <section className="modal-card-body">
          <Form
            discountDetails={discountDetails}
            onClose={onClose}
            loading={loading}
            onSubmit={onSubmit}
          />
        </section>
      </div>
    </Container>
  );
};

export default EditDiscountDetails;
