import React from 'react';
import styled from 'styled-components';
import { Column } from 'rbx';
// import { toast } from 'react-toastify';
// import swal from 'sweetalert';

import SelectDayAndTime from './SelectDayAndTime';
import HoursModal from './HoursModal';
import WeeklyHours from './WeeklyHours';
import Toggle from './Toggle';

const Container = styled.div`
  padding: 0.15rem 1rem;
`;

const CustomSchedule = ({
  userId,
  // dealId,
  placeId,
}) => {
  const [isActiveModal, setIsActiveModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dealSchedules, setDealSchedules] = React.useState(null);
  const [dealSchedule, setDealSchedule] = React.useState(null);

  const fetchFunc = async () => {
    setLoading(true);
    try {
      // const c = await fetchAllCategoriesOfThePlace({
      //   user_id,
      //   place_id,
      //   menu_id,
      // });
      // if (c) setCategories((c ?? []).filter(ele => ele.section_hour));
      // if (c)
      //   setExistingCategory([
      //     ...(c ?? [])
      //       .filter(ele => !ele.section_hour)
      //       .map(ele => ({ value: ele, label: ele.name })),
      //     { value: 'CREATE_NEW_CATEGORY', label: 'CREATE NEW CATEGORY' },
      //   ]);
      setDealSchedules(null);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchFunc();
  }, []);

  if (loading) {
    return (
      <Container>
        <Column.Group>
          <Column size="6">
            <SelectDayAndTime onClick={() => {}} />
          </Column>
        </Column.Group>
        <span style={{ color: '#00AEEF', padding: '1.5rem' }}>Loading...</span>
      </Container>
    );
  }

  return (
    <>
      <Container>
        <Toggle />
        <Column.Group>
          <Column size="6">
            {isActiveModal && (
              <HoursModal
                dealSchedule={dealSchedule}
                user_id={userId}
                place_id={placeId}
                isActive={isActiveModal}
                onClose={f => {
                  if (f) {
                    setIsActiveModal(false);
                  } else {
                    setIsActiveModal(false);
                    fetchFunc();
                  }
                }}
              />
            )}
            <SelectDayAndTime onClick={() => setIsActiveModal(true)} />
            {dealSchedules &&
              dealSchedules?.length !== 0 &&
              dealSchedules.map(ele => (
                <>
                  <WeeklyHours
                    hours={ele}
                    name="Deal Schedule"
                    onClick={() => {
                      setDealSchedule(ele);
                      setIsActiveModal(true);
                    }}
                  />
                </>
              ))}
          </Column>
        </Column.Group>
      </Container>
    </>
  );
};

export default CustomSchedule;
