/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Table } from 'rbx';
import gql from 'graphql-tag';
import swal from 'sweetalert';
import { useStoreState } from 'easy-peasy';
import { useMutation } from '@apollo/react-hooks';
import { isNil } from 'lodash';
import { toast } from 'react-toastify';

import {
  Table as CustomTable,
  Box,
  Input,
} from '../../../../components/elements';
import Header from './Header';
import List from './List';
import AddEditForm from './AddEditForm';
import client from '../../../../utils/apolloClient';
import { getAuthUrl } from './helpers';

const Container = styled.div`
  .table-container {
    min-height: 30rem;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin-bottom: 24px;
`;

const LinkButton = props => (
  <Button {...props} className={`${props.className} link-button`} />
);

// mutation MyMutation {
// 	update_place(
// 		input: {
// 			user_id: "4e650f5e-cf1b-4d0f-a715-8119b82c0150",
// 			place_id: "a193229d-3826-4a01-bf4d-f9fbc2f8cf76",
// 			pos_configuration: {
// 				platform: "SQUARE",
// 				unique_name: "square_location_id",
// 				unique_id: "LKZHJS8WYB1PM",
// 				service_type: ["PICKUP", "DELIVERY", "DINEIN", "TABLE_BOOKING"]
// 			}
// 		}
// 	) {
// 		place_id
// 		pos_configuration {
// 			platform
// 			unique_name
// 			unique_id
// 			service_type
// 		}
// 	}
// }

const updatePlaceMutation = gql`
  mutation updatePlace($input: PlaceInput) {
    update_place(input: $input) {
      place_id
      name
      error {
        code
        description
      }
    }
  }
`;

const Details = ({ posConfigs, refetch, placeId }) => {
  const { userId } = useStoreState(state => state.auth);
  const [isActiveModal, setIsActiveModal] = useState(false);
  const [editPosConfig, setEditPosConfig] = useState(null);

  const [isCopied, setIsCopied] = useState(false);
  const [fetchingUrl, setFetchingUrl] = useState(false);
  const [squarePosUrl, setSquarePosUrl] = useState('');

  const copyLink = text => {
    navigator.clipboard.writeText(text).then(
      // eslint-disable-next-line prefer-arrow-callback
      function() {
        setIsCopied(true);

        setTimeout(() => {
          setIsCopied(false);
        }, 5000);
      },
      // eslint-disable-next-line prefer-arrow-callback
      function(err) {
        console.error('Async: Could not copy text: ', err);
      },
    );
  };

  const [updatePlace, { loading }] = useMutation(updatePlaceMutation, {
    client: client.clientPrivate,
    onCompleted: ({ update_place }) => {
      if (!isNil(update_place.error)) {
        update_place.error.map(item => toast.error(item.description));
      } else {
        setIsActiveModal(false);
        refetch();
      }
    },
  });

  return (
    <React.Fragment>
      <Header setIsActiveModal={setIsActiveModal} />
      <GridContainer>
        <button
          style={{ marginTop: 'auto' }}
          className={`button is-primary is-outlined ${fetchingUrl &&
            'is-loading'}`}
          onClick={async () => {
            setFetchingUrl(true);

            const url = await getAuthUrl({ placeId });

            if (url) {
              setSquarePosUrl(url);
            }

            setFetchingUrl(false);
          }}
        >
          Get Square POS Integration URL
        </button>
        {fetchingUrl && (
          <span style={{ color: '#00AEEF' }}>fetching url...</span>
        )}
        {!fetchingUrl && squarePosUrl && (
          <Input
            label="Square POS Integration URL"
            value={squarePosUrl}
            disabled
            rightSideAction={
              <LinkButton
                type="button"
                onClick={e => {
                  e.stopPropagation();
                  if (!isCopied) {
                    copyLink(squarePosUrl);
                  }
                }}
              >
                {isCopied ? 'copied' : 'Copy Link'}
              </LinkButton>
            }
          />
        )}
      </GridContainer>
      <Box>
        <Container>
          <CustomTable>
            <Table.Head>
              <Table.Row>
                <Table.Heading>Platform</Table.Heading>
                <Table.Heading>Unique Name</Table.Heading>
                <Table.Heading>Unique ID</Table.Heading>
                <Table.Heading>Pickup</Table.Heading>
                <Table.Heading>Delivery</Table.Heading>
                <Table.Heading>Dinein</Table.Heading>
                <Table.Heading>Table booking</Table.Heading>
                <Table.Heading>Actions</Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              <List
                posConfigs={posConfigs}
                onEdit={config => {
                  setEditPosConfig(config);
                  setIsActiveModal(true);
                }}
                onDelete={async config => {
                  if (config) {
                    swal({
                      icon: 'warning',
                      text:
                        'Would you like to remove the pos integration from this place?',
                      buttons: ['Cancel', 'OK'],
                    }).then(async yes => {
                      if (yes) {
                        await updatePlace({
                          variables: {
                            input: {
                              user_id: userId,
                              place_id: placeId,
                              pos_configuration: {},
                            },
                          },
                        });
                      }
                    });
                  }
                }}
              />
            </Table.Body>
          </CustomTable>
        </Container>
      </Box>
      {isActiveModal && (
        <AddEditForm
          loading={loading}
          isActive={isActiveModal}
          editPosConfig={editPosConfig}
          onClose={() => {
            setEditPosConfig(null);
            setIsActiveModal(false);
          }}
          onSubmit={async values => {
            await updatePlace({
              variables: {
                input: {
                  user_id: userId,
                  place_id: placeId,
                  pos_configuration: { ...values },
                },
              },
            });
            setEditPosConfig(null);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default Details;
