/* eslint-disable func-names */
import React from 'react';

import styled from 'styled-components';
import { withFormik } from 'formik';
import * as yup from 'yup';
import { Label } from 'rbx';
import { toast } from 'react-toastify';

import { Input, CheckBox } from '../../../../components/elements';

yup.addMethod(yup.string, 'testNonNegativeValue', function(errorMessage) {
  return this.test(`test-non-negative-value`, errorMessage, function(value) {
    const { path, createError } = this;
    const num = +value;

    return num >= 0 || createError({ path, message: errorMessage });
  });
});

const Container = styled.div`
  &&& {
    z-index: 2000;
    .modal-background {
      background-color: rgba(13, 13, 13, 0.28);
    }
    .modal-card-body {
      height: 80vh;
      padding: 0rem 1rem;
    }
    .modal-card {
      max-height: calc(100vh - 140px);
      position: absolute;
      width: 40rem;
    }
    .modal-card-head,
    .modal-card-foot {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    .modal-card-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
    .modal-card-head {
      border-bottom: 0px;
      padding: 1.5rem 1rem;
      background: white;
    }
    .modal-card-foot {
      justify-content: flex-end;
      border: 0px;
      padding: 1rem;
      background: white;
    }
    a {
      font-size: 1rem;
    }
  }
`;

const FormContent = props => {
  const {
    onClose,
    setFieldValue,
    values,
    handleSubmit,
    loading,
    errors,
    handleChange,
    handleBlur,
    touched,
  } = props;

  return (
    <>
      <div className="columns is-multiline">
        <div className="column is-half">
          <Input
            label="Platform"
            name="platform"
            value={values.platform}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.platform && touched.platform && 'is-danger'}
            errors={errors.platform}
            touched={touched.platform}
            disableToastMessage
            required
          />
        </div>
        <div className="column is-half">
          <Input
            label="Unique Name"
            name="unique_name"
            value={values.unique_name}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.unique_name && touched.unique_name && 'is-danger'}
            errors={errors.unique_name}
            touched={touched.unique_name}
            disableToastMessage
            required
          />
        </div>
        <div className="column is-half">
          <Input
            label="Unique ID"
            name="unique_id"
            value={values.unique_id}
            onChange={handleChange}
            onBlur={handleBlur}
            className={errors.unique_id && touched.unique_id && 'is-danger'}
            errors={errors.unique_id}
            touched={touched.unique_id}
            disableToastMessage
            required
          />
        </div>
        <div className="column is-half">
          <Label>Service Types</Label>
          <CheckBox
            label="Pickup"
            onChange={v => setFieldValue('pickup', v)}
            value={values.pickup}
          />
          <CheckBox
            label="Delivery"
            onChange={v => setFieldValue('delivery', v)}
            value={values.delivery}
          />
          <CheckBox
            label="Dinein"
            onChange={v => setFieldValue('dinein', v)}
            value={values.dinein}
          />
          <CheckBox
            label="Table booking"
            onChange={v => setFieldValue('table_booking', v)}
            value={values.table_booking}
          />
        </div>
      </div>
      <footer className="modal-card-foot">
        <button type="button" className="button" onClick={onClose}>
          cancel
        </button>
        <button
          type="button"
          className={`button is-primary ${loading && 'is-loading'}`}
          onClick={handleSubmit}
        >
          Save
        </button>
      </footer>
    </>
  );
};

const Form = withFormik({
  mapPropsToValues: ({ posConfig }) => ({
    platform: posConfig?.platform || '',
    unique_name: posConfig?.unique_name || '',
    unique_id: posConfig?.unique_id || '',
    pickup:
      (posConfig?.service_type &&
        Array.isArray(posConfig?.service_type) &&
        posConfig?.service_type.find(ele => ele === 'PICKUP')) ||
      false,
    delivery:
      (posConfig?.service_type &&
        Array.isArray(posConfig?.service_type) &&
        posConfig?.service_type.find(ele => ele === 'DELIVERY')) ||
      false,
    dinein:
      (posConfig?.service_type &&
        Array.isArray(posConfig?.service_type) &&
        posConfig?.service_type.find(ele => ele === 'DINEIN')) ||
      false,
    table_booking:
      (posConfig?.service_type &&
        Array.isArray(posConfig?.service_type) &&
        posConfig?.service_type.find(ele => ele === 'TABLE_BOOKING')) ||
      false,
  }),

  validationSchema: yup.object().shape({
    platform: yup
      .string()
      .min(3, 'Platform must be at least 3 characters')
      .max(75, 'Platform must be less than 75 Characters!')
      .required('Platform is required!'),
    unique_name: yup
      .string()
      .min(3, 'Unique Name must be at least 3 characters')
      .max(75, 'Unique Name must be less than 75 Characters!')
      .required('Unique Name is required!'),
    unique_id: yup
      .string()
      .min(3, 'Unique Id must be at least 3 characters')
      .max(75, 'Unique Id must be less than 75 Characters!')
      .required('Unique Id is required!'),
  }),

  handleSubmit: (values, { props }) => {
    const input = {
      platform: values.platform,
      unique_name: values.unique_name,
      unique_id: values.unique_id,
    };

    const service_type = [];

    if (values.pickup) {
      service_type.push('PICKUP');
    }

    if (values.delivery) {
      service_type.push('DELIVERY');
    }

    if (values.dinein) {
      service_type.push('DINEIN');
    }

    if (values.table_booking) {
      service_type.push('TABLE_BOOKING');
    }

    if (service_type.length === 0) {
      toast.error('Please select atleast one service type!');
      return;
    }

    input.service_type = service_type;

    props.onSubmit({
      ...input,
    });
  },
  enableReinitialize: true,
  displayName: 'Add / Edit POS Integrations',
})(FormContent);

const Modal = props => {
  const { isActive, onClose, loading, onSubmit, editPosConfig } = props;
  console.log(editPosConfig);

  return (
    <Container className={`modal ${isActive && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Add / Edit POS Integration</p>
          <button
            type="button"
            className="delete"
            aria-label="close"
            onClick={onClose}
          />
        </header>
        <section className="modal-card-body">
          <Form
            posConfig={editPosConfig}
            onClose={onClose}
            loading={loading}
            onSubmit={onSubmit}
          />
        </section>
      </div>
    </Container>
  );
};

export default Modal;
