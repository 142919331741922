import React from 'react';
import styled from 'styled-components';
import { Box, Column, Label } from 'rbx';
import { toast } from 'react-toastify';
import swal from 'sweetalert';

import AddCategoryHoursButton from './AddCategoryHoursButton';
import HoursModal from './HoursModal';
import { fetchAllCategoriesOfThePlace, updateMenuSections2 } from './helpers';
import WeeklyHours from './WeeklyHours';
import Toggle from './Toggle';
import {
  CheckBox,
  InputAddOn,
  Select,
} from '../../../../../components/elements';
import EditDiscountDetails from './EditDiscountDetails';

const Container = styled.div`
  padding: 0.15rem 1rem;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 75vw;
`;

const GridContainer2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const DiscountValueInput = ({ type, value }) => {
  if (type === 'PERCENTAGE') {
    return (
      <InputAddOn
        label="Discount Value"
        addonsText="%"
        isLeft
        disabled
        value={value}
      />
    );
  }

  if (type === 'FLAT') {
    return (
      <InputAddOn
        label="Discount Value"
        addonsText="$"
        isRight
        disabled
        value={value}
      />
    );
  }

  return (
    <InputAddOn
      label="Discount Value"
      addonsText=""
      isRight
      disabled
      value=""
    />
  );
};

const TimeBasedCategory = ({
  menuName,
  user_id,
  menu_id,
  place_id,
  serviceType,
}) => {
  const [isActiveModal, setIsActiveModal] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [category, setCategory] = React.useState();
  const [existingCategory, setExistingCategory] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [isActiveEditModal, setIsActiveEditModal] = React.useState(false);
  const [discountDetails, setDiscountDetails] = React.useState(null);
  const [updateDiscountDetails, setUpdateDiscountDetails] = React.useState(
    false,
  );

  const fetchFunc = async () => {
    setLoading(true);
    setCategory();
    setExistingCategory();
    try {
      const c = await fetchAllCategoriesOfThePlace({
        user_id,
        place_id,
        menu_id,
      });
      if (c) setCategories((c ?? []).filter(ele => ele.section_hour));
      if (c)
        setExistingCategory([
          ...(c ?? [])
            .filter(ele => !ele.section_hour)
            .map(ele => ({ value: ele, label: ele.name })),
          { value: 'CREATE_NEW_CATEGORY', label: 'CREATE NEW CATEGORY' },
        ]);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    fetchFunc();
  }, []);
  console.log('categories...', categories);

  if (loading) {
    return (
      <Container>
        <Column.Group>
          <Column size="6">
            <AddCategoryHoursButton onClick={() => {}} />
          </Column>
        </Column.Group>
        <span style={{ color: '#00AEEF', padding: '1.5rem' }}>Loading...</span>
      </Container>
    );
  }

  return (
    <>
      {isActiveEditModal && (
        <EditDiscountDetails
          loading={updateDiscountDetails}
          isActive={isActiveEditModal}
          discountDetails={discountDetails}
          onClose={() => {
            setIsActiveEditModal(false);
            setDiscountDetails(null);
          }}
          onSubmit={async ({ input }) => {
            console.log('input', input);
            setUpdateDiscountDetails(true);
            const res = await updateMenuSections2({
              user_id,
              ...input,
            });

            if (!res.isError) {
              swal(
                `Successfully saved discount details of ${discountDetails.name} category`,
              ).then(() => {
                setDiscountDetails(null);
                setIsActiveEditModal(false);
              });
              fetchFunc();
            } else {
              toast.error(res.error);
            }
            setUpdateDiscountDetails(false);
          }}
        />
      )}
      <Container>
        <Column.Group>
          <Column size="6">
            {isActiveModal && (
              <HoursModal
                existingCategory={existingCategory}
                menuName={menuName}
                user_id={user_id}
                menu_id={menu_id}
                place_id={place_id}
                serviceType={serviceType}
                isActive={isActiveModal}
                onClose={f => {
                  if (f) {
                    setCategory();
                    setIsActiveModal(false);
                  } else {
                    setCategory();
                    setIsActiveModal(false);
                    fetchFunc();
                  }
                }}
                category={category}
              />
            )}
            <AddCategoryHoursButton onClick={() => setIsActiveModal(true)} />
            {categories.length !== 0 &&
              categories.map(ele => (
                <>
                  <GridContainer>
                    <WeeklyHours
                      hours={ele.section_hour}
                      name={ele.name}
                      onClick={() => {
                        setCategory(ele);
                        setIsActiveModal(true);
                      }}
                    />
                    <Box>
                      <div className="is-flex is-justify-content-space-between">
                        <p
                          style={{
                            textDecoration: 'underline',
                          }}
                        >
                          {ele.name} Discount Details
                        </p>
                        <div
                          className="is-size-2"
                          style={{
                            textAlign: 'right',
                            cursor: 'pointer',
                            color: '#00AEEF',
                            textDecoration: 'underline',
                          }}
                          onClick={() => {
                            setIsActiveEditModal(true);
                            setDiscountDetails(ele);
                          }}
                        >
                          Edit
                        </div>
                      </div>
                      <br />
                      <GridContainer2>
                        <Label>Apply Discount</Label>
                        <Toggle
                          checked={ele.is_time_based_category_discount || false}
                          disabled
                        />
                      </GridContainer2>
                      <br />
                      <GridContainer2>
                        <div style={{ margin: '0 12px 0 0' }}>
                          <Select
                            label="Discount Type"
                            value={[
                              {
                                value: ele?.discount?.discount_type || '',
                                label: ele?.discount?.discount_type || '',
                              },
                            ]}
                            disabled
                          />
                        </div>
                        <DiscountValueInput
                          type={ele?.discount?.discount_type}
                          value={ele?.discount?.value}
                        />
                      </GridContainer2>
                      <br />
                      <CheckBox
                        label="Do not club with other deals"
                        value={ele.is_club_deal || false}
                        disabled
                      />
                    </Box>
                  </GridContainer>
                  <br />
                </>
              ))}
          </Column>
        </Column.Group>
      </Container>
    </>
  );
};

export default TimeBasedCategory;
